$(document).ready( function() {
    $('#submit-form').attr('disabled', 'disabled');
    $('#customCheck1').removeAttr('checked');
    $('#send-form').hide();
    $('#hide-form').show();

    $('#customCheck1').click(function () {
        if ($(this).is(':checked')) {
            $('#submit-form').removeAttr('disabled');
        } else {
            $('#submit-form').attr('disabled', 'disabled');
        }
    });

    $("#submit-form").unbind().on('click' , submit_form);
    function submit_form() {
        $('.form-group').removeClass("has-error");
		var name=$("input#name").val();
        if( name.length < 1 ) {
            $("#name").parent(".form-group").addClass("has-error");
			$("#name").select().focus();
            return false;
        }
        var email=$("input#email").val();
        if( email.length < 1 ) {
            $("#email").parent(".form-group").addClass("has-error");
			$("#email").select().focus();
            return false;
        }
        var message=$("textarea#message").val();
        if( message.length < 1 ) {
            $("#message").parent(".form-group").addClass("has-error");
			$("#message").select().focus();
            return false;
        }

        var checkbox = $("#customCheck1").is(":checked");
        var rule=$("label#rule").text(); 

        $('#submit-form').attr('disabled', 'disabled');
        $('#submit-form').html("<span class='fas fa-spinner fa-spin'></span><span> Wysyłam</span>");
        
        $.ajax({
            type: "POST",
            url: "/ajax/system/contact-form",
            data: { name: name, email: email, message: message, rule: rule },
            success: function( r ) {
                console.log('informacja po success:');
                console.log( r );
                if( r.result == true ) {
                    $('#hide-form').hide();
                    $('#send-form').show();
                } 
            }
        });

        return false;

    };
});