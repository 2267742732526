$(function () {
	  $('[data-toggle="tooltip"]').tooltip()
	})
//loader
$(window).on("load", function () {
    // Animate loader off screen
    $(".se-pre-con").fadeOut(500);
	
});
$(document).ready(function () {
	
	$('.icon-gabinet a').simpleLightbox();
	$('.enlarge').simpleLightbox();
	 $('.cookie-message').cookieBar({
        closeButton: '.btn'
    });
	$(function () {
        $('a[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - 120
                    }, 1000);
                    return false;
                }
            }
        });
    });
	if ($(window).width() > 992) {
        // Add slideDown animation to Bootstrap dropdown when expanding.
        $('.dropdown').on('show.bs.dropdown', function () {
            $(this).find('.dropdown-menu').first().stop(true, true).slideDown();
        });

        // Add slideUp animation to Bootstrap dropdown when collapsing.
        $('.dropdown').on('hide.bs.dropdown', function () {
            $(this).find('.dropdown-menu').first().stop(true, true).slideUp();
        });
    };

    //navbar-menu

    var xs = 575;
    var sm = 767;
    var md = 991;
    var lg = 1199;
    var xl = 1439;
    var x2 = 1719;

    var navMenu = $('.navbar-menu');
    var navToggler = $('.navbar-toggler', navMenu);
    var navCollapse = $('.navbar-collapse', navMenu);
    var navBreakPoint = lg;

    navToggler.click(function () {
        if (navCollapse.hasClass('menu-show') == true) {
            navCollapse.css('opacity', '0');
            setTimeout(function () {
                navCollapse.css('display', 'none')
            }, 500);
            navCollapse.removeClass('menu-show');
            navToggler.removeClass('menu-show');
            $('body').css('overflow', 'auto');
        } else {
            navCollapse.addClass('menu-show');
            navToggler.addClass('menu-show');
            navCollapse.css('display', 'flex');
            setTimeout(function () {
                navCollapse.css('opacity', '1')
            }, 100);
            $('body').css('overflow', 'hidden');

        }
    });


    //navbar-menu
    if ($(window).outerWidth() > navBreakPoint) {
        navCollapse.css('display', 'flex');
        navCollapse.css('opacity', '1');
        $('body').css('overflow', 'auto');
    } else {

        navCollapse.css('display', 'none');
        navCollapse.css('opacity', '0');
    }

    $(window).resize(function () {
        if (this.resizeTO) clearTimeout(this.resizeTO);
        this.resizeTO = setTimeout(function () {
            $(this).trigger('resizeEnd');
        }, 1);
    });

    $(window).bind('resizeEnd', function () {
        if ($(window).outerWidth() > navBreakPoint) {
            navCollapse.css('display', 'flex');
            navCollapse.css('opacity', '1');
            $('body').css('overflow', 'auto');


        } else {

            if (navCollapse.hasClass('menu-show') !== true) {
                navCollapse.css('display', 'none');
                navCollapse.css('opacity', '0');
            }

        }
    });

//slider główny
    var sliderMain = $(".header-slides");
    var sliderMainNav = $(".navigation-slider", sliderMain);
    if (sliderMain.length && $(".swiper-slide", sliderMain).length > 1) {
        var swiperMain = new Swiper(sliderMain, {
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            speed: 1000,
            slidesPerView: 1,
            loop: true,
            allowTouchMove: true,
            autoHeight: false,
            spaceBetween: 0,
            autoplay: {
                delay: 10000,
            },
            navigation: {
                nextEl: $('.nav-next', sliderMain),
                prevEl: $('.nav-prev', sliderMain),
            },
            pagination: {
			  el: '.swiper-pagination',
			  clickable: true,
			 renderBullet: function (index, className) {
				return '<span class="' + className + '"></span>';
			  },
			},
        });
        $(".btn-primary", sliderMain).off().hover(function () {
            swiperMain.autoplay.stop();
        }, function () {
            swiperMain.autoplay.start();
        });

        swiperMain.on('slideNextTransitionStart', function () {
            sliderMainNav.css('opacity', "0");
            $(".wow-slide").css('animation', "fadeOutRightBig");
            $(".wow-slide").css('animation-duration', "1s");
            $(".wow-slide").css('transition', ".2s all linear");
        });
		swiperMain.on('slidePrevTransitionStart', function () {
            sliderMainNav.css('opacity', "0");
            $(".wow-slide").css('animation', "fadeOutLeftBig");
            $(".wow-slide").css('animation-duration', "1s");
            $(".wow-slide").css('transition', ".2s all linear");
        });
		
        swiperMain.on('slideNextTransitionEnd', function () {
            sliderMainNav.css('opacity', "1");
            $(".wow-slide").css('animation', "fadeInUp");
            $(".wow-slide").css('animation-duration', "1s");
            $(".wow-slide").css('transition', "1s all linear");
        });
        swiperMain.on('slidePrevTransitionEnd', function () {
            sliderMainNav.css('opacity', "1");
            $(".wow-slide").css('animation', "fadeInUp");
            $(".wow-slide").css('animation-duration', "1s");
            $(".wow-slide").css('transition', "1s all linear");
        });
    };
    
    //slider Poznajmy się lepiej
    var sliderAbout = $(".slider-about");
    var sliderAboutNav = $(".navi-box", sliderAbout);
    if (sliderAbout.length && $(".swiper-slide", sliderAbout).length > 1) {
        var swiperMain = new Swiper(sliderAbout, {
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            speed: 1000,
            slidesPerView: 1,
            loop: true,
            allowTouchMove: true,
            autoHeight: false,
            spaceBetween: 0,
            autoplay: {
                delay: 10000,
            },
            navigation: {
                nextEl: $('.about-nav-next', sliderAbout),
                prevEl: $('.about-nav-prev', sliderAbout),
            }
        });
    };
	


var gabinetSwiper = new Swiper('.inner-gabinet', {
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    slidesPerView: 3,
    allowTouchMove: true,
    spaceBetween: 30,
    autoplay: {
        delay: 5000,
    },
	initialSlide: 0,
	updateOnWindowResize: true,
    // Pagination
    pagination: {
        el: '.swiper-pagination1',
            clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '"></span>';
        },
    },
    // Navigation arrows
    navigation: {
    nextEl: '.gabinet-nav-next',
    prevEl: '.gabinet-nav-prev',
    },
    breakpoints: {
        1200: {
            slidesPerView: 3
        },
        992: {
            slidesPerView: 3
        },
        768: {
            slidesPerView: 2
        },
        576: {
            slidesPerView: 1
        }
    }
});
// Swiper end
wow = new WOW(
                      {
                      boxClass:     'wow',      // default
                      animateClass: 'animate__animated', // default
                      offset:       100,          // default
                      mobile:       false,       // default
                      live:         false        // default
                    }
                    )
                    wow.init();
});
 var stylesMap = [{"featureType":"administrative","elementType":"all","stylers":[{"visibility":"simplified"},{"hue":"#d400ff"}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"visibility":"simplified"},{"color":"#ffffff"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"visibility":"simplified"},{"color":"#f8f8f8"}]},{"featureType":"poi.park","elementType":"labels","stylers":[{"visibility":"simplified"},{"hue":"#d400ff"}]},{"featureType":"road","elementType":"labels","stylers":[{"visibility":"off"},{"hue":"#00ffe4"}]},{"featureType":"road","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"visibility":"simplified"},{"color":"#dddddd"}]},{"featureType":"road.highway","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"road.highway","elementType":"labels.icon","stylers":[{"visibility":"on"},{"hue":"#00ffe4"}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"visibility":"simplified"},{"color":"#dddddd"}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"visibility":"simplified"},{"color":"#eeeeee"}]},{"featureType":"transit.station.airport","elementType":"geometry","stylers":[{"visibility":"off"},{"hue":"#d400ff"}]},{"featureType":"water","elementType":"geometry","stylers":[{"visibility":"simplified"},{"color":"#dddddd"}]}]

if ($("#map").length) {
    var map;
    function initMap() {
        map = new google.maps.Map(document.getElementById('map'), {
            zoom: 12,
            center: new google.maps.LatLng(51.108660, 17.120495),
            mapTypeId: 'roadmap',
            //disableDefaultUI: true,
            styles: stylesMap,
        });
        var iconBase = 'images/map-pin.png';
        var mapMarker = {
            url: iconBase,
            anchor: new google.maps.Point(1,46),
        };
        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(51.108660, 17.120495),
            icon: mapMarker,
            map: map
        });
    };
}